<template>
    <b-modal size="lg" id="show-role-modal" ref="ref-show-role-modal" v-model="show" hide-footer :title="titleShowRole">
        <validation-observer ref="createRolForm">
            <b-form class="mt-2" @submit.prevent="createRol">

                <b-container>
                    <b-row>
                        <b-col>
                            <b-form-group label="Nombre" label-for="rolData-name">
                                <validation-provider #default="{ errors }" name="name" rules="required">
                                    <b-form-input id="rolData-name" v-model="rolData.name"
                                        :state="errors.length > 0 ? false : null" name="name" />
                                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col>
                            <div class="d-flex justify-content-center align-items-center py-3">
                                <b-button type="submit" variant="primary">Guardar</b-button>
                            </div>
                        </b-col>
                    </b-row>

                </b-container>


            </b-form>
        </validation-observer>

    </b-modal>
</template>
  
<script>
import {
    BModal,
    BContainer,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const rolDataInitial = {
    id: null,
    name: null,
}


export default {
    name: 'AddUpdateRole',
    components: {
        BModal,
        BContainer,
        BRow,
        BCol,
        ValidationObserver,
        ValidationProvider,
        BForm,
        BFormGroup,
        BFormInput,
        BButton,
    },
    data() {
        return {
            rolData: Object.assign({}, rolDataInitial),
        }
    },
    computed: {
        ...mapState({
            // identificationTypes: (state) => state.app.identificationTypes,
        }),
    },
    props: {
        titleShowRole: {
            type: String,
            required: true,
        },
        show: {
            type: Boolean,
            required: true,
            default: false,
        },
        hiddenModal: {
            type: Function,
            required: true,
            default: () => null,
        },
        reloadData: {
            type: Function,
            required: true,
            default: () => null,
        },
        item: {
            type: Object,
            default: null,
        },
    },

    methods: {
        createRol() {
            this.$refs.createRolForm.validate().then(async (success) => {
                if (success) {
                    const res = await this.$store.dispatch('roles/save',
                        {
                            id: this.rolData.id,
                            name: this.rolData.name
                        }
                    )

                    if (res.success) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Rol guardado correctamente',
                                icon: 'CheckCircleIcon',
                                variant: 'success',
                            },
                        })
                        this.$refs['ref-show-role-modal'].hide()
                        this.rolData = Object.assign({}, rolDataInitial)
                        this.hiddenModal()
                        this.reloadData()
                    } else {
                        this.$swal('Error', "Hubo un error al guardar el usuario", 'error')
                    }

                }
            })
        },
    },

    watch: {
        item: {
            handler: function (val) {
                if (val) {
                    this.rolData = Object.assign({}, val)
                } else {
                    this.rolData = Object.assign({}, rolDataInitial)
                }
            },
            deep: true,
        },
    },

    mounted() {
        this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
            this.hiddenModal()
        })
    },
}
</script>
  