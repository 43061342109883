<template>
  <div>
    <app-breadcrumb section="Sistema" :breadcumbs="['Permisos']" />

    <!-- <template v-if="verifyPermission({ namePerm: 'create-role' })"> -->
    <div class="row" v-if="permissions_requerid.includes('create-role')">
      <div class="col-12 text-left mb-2">
        <b-button variant="primary" class="px-1" @click="create()">
          Agregar
        </b-button>
      </div>
    </div>
    <!-- </template> -->

    <div class="row">
      <div class="col">
        <b-overlay :show="loading_data" rounded="sm">
          <b-card>
            <b-card-body>
              <b-tabs>
                <b-tab v-for="(role, index) of roles" :key="index" :title="role.name">
                  <template #title>
                    <strong>{{ role.name }}</strong>
                    <b-button variant="default" class="p5px" v-b-tooltip.hover title="Editar" @click="edit(role)"
                      v-if="permissions_requerid.includes('edit-role')">
                      <feather-icon icon="SettingsIcon" class="m-0 green" />
                    </b-button>

                    <!-- <b-button variant="default" class="p5px" v-b-tooltip.hover title="Eliminar"
                      @click="deleteItem(role)" v-if="permissions_requerid.includes('delete-role')">
                      <feather-icon icon="TrashIcon" class="m-0 red" />
                    </b-button> -->
                  </template>


                  <b-card-text>
                    <div class="row">

                      <div class="col">
                        <b-tabs>
                          <b-tab v-for="(item, indexNew) of role.modules" :key="indexNew" :title="item.name">
                            <b-card-text>
                              <div class="d-flex align-items-center justify-content-end">
                                <b-form-checkbox switch size="lg" v-model="item.active" @change="
                                  changeStatusModulePermission({
                                    ...item,
                                    role_id: role.id,
                                  })
                                " color="primary">
                                  <span class="switch-icon-left">
                                    <feather-icon icon="CheckIcon" />
                                  </span>
                                  <span class="switch-icon-right">
                                    <feather-icon icon="XIcon" />
                                  </span>
                                </b-form-checkbox>
                              </div>

                              <div class="card">
                                <div class="card-body">
                                  <div v-if="item.submodules.length" class="table-responsive mb-0">
                                    <table class="table table-nowrap table-centered">
                                      <tbody>
                                        <tr v-for="(
                                            submodule, indexSubModules
                                          ) of item.submodules" :key="indexSubModules">
                                          <td style="width: 60px">
                                            <div class="custom-control custom-checkbox">
                                              <input v-model="submodule.active" @change="
                                                changeStatusSubModulePermission(
                                                  submodule,
                                                  role.id
                                                )
                                              " :id="`customCheck1${submodule.id}-${role.id}`" type="checkbox"
                                                class="custom-control-input" />
                                              <label class="custom-control-label"
                                                :for="`customCheck1${submodule.id}-${role.id}`"></label>
                                            </div>
                                          </td>
                                          <td>
                                            <h5 class="text-truncate font-size-14 m-0">
                                              <a href="javascript: void(0);" class="text-dark">
                                                {{ submodule.name }}
                                              </a>
                                            </h5>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div v-else>
                                    <h3 class="text-center">
                                      Este modulo no tiene submodulos
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </b-card-text>
                          </b-tab>
                        </b-tabs>
                      </div>
                    </div>
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </b-card-body>
          </b-card>
        </b-overlay>
      </div>
    </div>
    <AddUpdateRole :show="showRole" :hiddenModal="hiddenModal" :titleShowRole="titleShowRole" :reloadData="reloadData"
      :item="item" />
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import {
  BTabs,
  BTab,
  BOverlay,
  BCard,
  BCardBody,
  BCardText,
  BFormCheckbox,
  BButton,
  BDropdown,
  BDropdownItem,
  BTooltip
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddUpdateRole from "./components/AddUpdateRole.vue"
import { confirmAlert } from '@/helpers/utils'
import { verifyPermission } from '@/router';


export default {
  components: {
    BFormCheckbox,
    AppBreadcrumb,
    BTabs,
    BTab,
    BOverlay,
    BCard,
    BCardBody,
    BCardText,
    BButton,
    BDropdown,
    BDropdownItem,
    BTooltip,
    AddUpdateRole,
    verifyPermission
  },

  data() {
    return {
      roles: [],
      loading_data: false,
      item: null,
      showRole: false,
      titleShowRole: "",
      permissions_requerid: []
    }
  },
  methods: {
    create() {
      this.item = null
      this.titleShowRole = "Crear rol"
      this.showRole = true
    },
    edit(item) {
      this.item = item
      this.titleShowRole = "Editar rol"
      this.showRole = true
    },
    async deleteItem(item) {
      const res = await confirmAlert('¿Está seguro de eliminar este rol?')
      if (res.value) {
        const response = await this.$store.dispatch('roles/delete', {
          id: item.id,
        })
        if (response.success) {
          this.toast(response.message)
          this.getRolesAndPermissions()
        } else {
          this.$swal("Error", response.message, '', 'error')
        }
      }
    },
    hiddenModal() {
      this.item = null
      this.titleShowRole = ""
      this.showRole = false
    },
    reloadData() {
      this.getRolesAndPermissions()
    },
    async getRolesAndPermissions() {
      this.loading_data = true
      this.$store
        .dispatch('roles/getRolesAndPermissions', {})
        .then((response) => {
          this.roles = response
          this.loading_data = false
        })
    },
    async changeStatusModulePermission(data) {
      this.$store
        .dispatch('roles/changeStatusModulePermission', {
          module_id: data.id,
          role_id: data.role_id,
          status: data.active,
        })
        .then((response) => {
          if (response.success) {
            this.toast(response.message)
          } else {
            Swal.fire(response.message, '', 'error')
          }
        })
    },
    async changeStatusSubModulePermission(data, role_id) {
      this.$store
        .dispatch('roles/changeStatusSubModulePermission', {
          submodule_id: data.id,
          role_id: role_id,
          status: data.active,
        })
        .then((response) => {
          if (response.success) {
            this.toast(response.message)
          } else {
            Swal.fire(response.message, '', 'error')
          }
        })
    },
    toast(title, variant = 'success', icon = 'CheckCircleIcon') {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          variant: variant,
        },
      })
    },
  },
  mounted() {
    this.getRolesAndPermissions()

    // Verify
    let create = verifyPermission({ namePerm: 'create-role' })
    let edit = verifyPermission({ namePerm: 'edit-role' });
    let delete_var = verifyPermission({ namePerm: 'delete-role' })

    let permissions_requerid_verify = []
    if (create) {
      permissions_requerid_verify.push("create-role")
    }
    if (edit) {
      permissions_requerid_verify.push("edit-role")
    }
    if (delete_var) {
      permissions_requerid_verify.push("delete-role")
    }
    this.permissions_requerid = permissions_requerid_verify
    // permissions_requerid.includes('edit-role')

  },
}
</script>

<style>
.green {
  color: green;
}

.red {
  color: red;
}

.p5px {
  padding: 5px 5px;
}
</style>
